export const environment = {
    type:'DEV',
    production: false,
    base_url : "https://devapi-lb.creditfair.in/v0/",
    //base_url : "http://localhost:5001/v0/",
    base_url_py : "https://devapi-py.creditfaircapital.in/v0/service/",
    password : "770A8A65DA156D24EE2A093277530142",
    key : "x2tlf194y0manbe55",
    DISABLE_ERROR:false
  };
